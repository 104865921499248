import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import VueTheMask from "vue-the-mask";
import VueSweetalert2 from "vue-sweetalert2";

import "./style/style.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "./utils/validator";

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);
Vue.use(VueTheMask);

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
