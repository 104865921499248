import { configure, extend } from "vee-validate";
import { required, length, min } from "vee-validate/dist/rules";

configure({
  classes: {
    // valid: 'is-valid',
    // invalid: 'is-invalid',
    // dirty: 'is-dirty',
  },
});

extend("length", {
  ...length,
  message: "Длина поля должна быть {length}",
});

extend("min", {
  ...min,
  message: "Поле должно быть не менее {length} символов",
});

extend("required", {
  ...required,
  message: "Поле обязательно для заполнения",
});

extend("samePassword", {
  params: ["password"],
  validate: (value, { password }) => {
    return password === value;
  },
  message: "Пароли не совпадают",
});
